$(document).on('click', '.tabbed-section .dropdown-menu .dropdown-item', function () {
  $(this).closest('.dropdown-menu').children().removeClass('active').attr('aria-selected', false).attr('aria-expanded', false);
  $(this).addClass('active').attr('aria-selected', true).attr('aria-expanded', true);
  $(this).closest('.dropdown').find('.dropdown-toggle').text($(this).text())
});

$(document).on('click', '.tabbed-section .tabs-menu .tab-item', function () {
  var $tabsMenu = $(this).closest('.tabs-menu');
  $tabsMenu.children().removeClass('active').attr('aria-selected', false).attr('aria-expanded', false);
  $(this).addClass('active').attr('aria-selected', true).attr('aria-expanded', true);
  $tabsMenu.siblings('.tab-content').removeClass('active');
});