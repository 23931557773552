jQuery(document).ready(function($) {
  if(!$('.mark-as-played').length > 0) { return }
  var button = $('.mark-as-played'); // Reference to the button
  var checkbox = $('.certify-as-played'); // Reference to the checkbox
  var usernameInput = $('.casino-user-id'); // Reference to the username/email input field
  
  // Function to enable or disable the button based on the checkbox and username/email field
  function toggleButtonState() {
    
    var isChecked = checkbox.is(':checked');
    var isUsernameFilled = usernameInput.val().trim() !== '';

    button.prop('disabled', !(isChecked && isUsernameFilled));
  }

  // Initially disable the button
  toggleButtonState();

  // Toggle button state when the checkbox is clicked
  checkbox.on('click', toggleButtonState);

  // Toggle button state when the username/email field value changes
  usernameInput.on('input', toggleButtonState);

  $('.mark-as-played').on('click', function() {
    var casinoId = button.data('casino-id');
    var username = usernameInput.val().trim(); // Get the value of the username/email field
    var certifyAffSignup = $('.certify-as-played').is(':checked'); // Get the checked status of the certify-as-played checkbox
    var certifyThirtyDays = $('.thirty-days').is(':checked'); // Get the checked status of the thirty-days checkbox

    button.prop('disabled', true);

    // AJAX call
    $.ajax({
      url: theme_ajax.url,
      type: 'POST',
      data: {
        action: 'mark_casino_as_played',
        casino_id: casinoId,
        username: username,
        certify_affsignup: certifyAffSignup, // Pass the checked status to the server
        certify_thirtydays: certifyThirtyDays // Pass the checked status to the server
      },
      success: function(response) {
        console.log(response);
        button.text('Marked as Played');
      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.error(textStatus, errorThrown);
        button.prop('disabled', false);
      }
    });
  });
});
