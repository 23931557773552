$(document).ready(function () {
  $('.container-slider').not('.competitions-slider, .offers-slider').each(function (index, element) {
    const slider = $(element).find('.slider');
    const count = $(element).data('items-count');

    slider.slick({
      slidesToShow: count < 5 ? count : 5,
      slidesToScroll: 1,
      infinite: false,
      // variableWidth: true,
      arrows: true,
      dots: false,
      prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 12l4-4M14 16l-4-4" stroke="#74B9FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
      nextArrow: '<button class="slick-next" aria-label="Next" type="button"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 12l-4 4M10 8l4 4" stroke="#74B9FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
      responsive: [
        {
          breakpoint: 1680,
          settings: {
            slidesToShow: count < 4 ? count : 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: count < 3 ? count : 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: count < 2 ? count : 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    })
  });

  $('.competitions-slider, .offers-slider').each(function (index, element) {
    const slider = $(element).find('.slider');
    const count = $(element).data('items-count');
    console.log(count);
    slider.slick({
      slidesToShow: count < 3 ? count : 3,
      slidesToScroll: 1,
      infinite: false,
      // variableWidth: true,
      arrows: true,
      dots: false,
      prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 12l4-4M14 16l-4-4" stroke="#74B9FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
      nextArrow: '<button class="slick-next" aria-label="Next" type="button"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 12l-4 4M10 8l4 4" stroke="#74B9FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
      responsive: [
        {
          breakpoint: 1680,
          settings: {
            slidesToShow: count < 3 ? count : 3,
            slidesToScroll: 1,
            // count < 1 ? count : 1
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: count < 3 ? count : 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: count < 2 ? count : 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    })
  });

  $('.carousel3d').each(function (index, element) {
    new Carousel3d(element);
  })
});