$( ".deposit-methods .show-more" ).on('click', function() {
  $(this).hide();
  $(this).parents('.deposit-methods').find('.more').slideToggle().css('display', 'flex');
  
});

$( ".game-providers .show-more" ).on('click', function() {
  $(this).hide();
  $(this).parents('.game-providers').find('.more').slideToggle().css('display', 'flex');
  
});