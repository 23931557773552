// Function to set a cookie
function setCookie(name, value, days) {
  var expires = "";
  if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}

// Function to handle theme toggle
function toggleTheme() {
  var body = document.body;
  var theme = body.getAttribute('data-bs-theme');

  if (theme === 'dark') {
      body.removeAttribute('data-bs-theme');
      setCookie('site-theme', 'light', 7);
  } else {
      body.setAttribute('data-bs-theme', 'dark');
      setCookie('site-theme', 'dark', 7);
  }
}

// Add click event listener to the theme switch toggle button
document.getElementById('theme-switch-toggle').addEventListener('click', toggleTheme);