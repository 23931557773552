import * as bootstrap from 'bootstrap';

document.addEventListener('DOMContentLoaded', function () {
  let modalShown = false;
  let modalElements = document.querySelectorAll('.modal.exit-intent, .modal.popup');

  // Function to set a cookie
  function setCookie(name, value, days) {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  // Function to get a cookie
  function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  // Function to show modal
  function showModal(modalElement) {
    if (!modalShown) {
      modalShown = true;
      let modal = new bootstrap.Modal(modalElement);
      modal.show();

      // Set a cookie when the modal is closed
      modalElement.addEventListener('hidden.bs.modal', function () {
        setCookie('modalShown', 'true', 7); // Set cookie for 1 week
      });
    }
  }

  // Handle exit intent
  if (!getCookie('modalShown')) {
    setTimeout(function() {
      document.addEventListener('mouseleave', function (event) {
        if (event.clientY < 0) {
          let exitIntentModal = document.querySelector('.modal.exit-intent');
          if (exitIntentModal) {
            showModal(exitIntentModal);
          }
        }
      });
    }, 5000); // 5000 milliseconds = 5 seconds delay before exit intent detection
  }

  // Handle timed popups
  modalElements.forEach(function(modalElement) {
    if (modalElement.classList.contains('popup') && !getCookie('modalShown')) {
      let delay = parseInt(modalElement.dataset.popupDelay) || 0;
      setTimeout(function() {
        showModal(modalElement);
      }, delay * 1000); // Convert seconds to milliseconds
    }
  });
});