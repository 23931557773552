export default class Carousel3d {

  constructor(el, cfg) {
    this.init(el, cfg)
  }

  init(el, cfg) {
    const defaults = {
      visible: 5,
      active: 3,
      start: 0,
      // padding: (cfg.visible - cfg.active) / 2,
      // classes: ['carousel3d-item-most-left', 'carousel3d-item-left', 'carousel3d-item-center-left', 'carousel3d-item-center-center', 'carousel3d-item-center-right', 'carousel3d-item-right', 'carousel3d-item-most-right'],
      classes: ['carousel3d-item-left', 'carousel3d-item-center-left', 'carousel3d-item-center-center', 'carousel3d-item-center-right', 'carousel3d-item-right'],
      previous: el.querySelector('.carousel3d-arrow.previous'),
      next: el.querySelector('.carousel3d-arrow.next')
    };

    let new_list = Array.from(el.querySelectorAll('.carousel3d-item'));

    const first = new_list.shift()
    const second = new_list.shift()
    new_list.unshift(first)
    new_list.unshift(second)

    for (let i = 0; i < 1; i++) {
      const item = new_list[new_list.length - 1];
      new_list.unshift(item);
      new_list.pop();
    }

    this.cfg = Object.assign({}, defaults, cfg);
    this.cfg.list = new_list;
    this.cfg.listLength = new_list.length;

    this.calculated = {start: 1, end: 0, listLength: 0};
    this.updateValues();
    this.bindEvents();
  }

  bindEvents() {
    this.cfg.previous.addEventListener('click', () => this.previous());
    this.cfg.next.addEventListener('click', () => this.next());
  }

  previous() {
    this.calculated.start--;
    this.updateValues();
  }

  next() {
    this.calculated.start++;
    this.updateValues();
  }

  updateValues() {
    if (this.calculated.start > this.cfg.listLength) {
      this.calculated.start = 1;
    } else if (this.calculated.start < 1) {
      this.calculated.start = this.cfg.listLength;
    }
    const start = this.calculated.start;
    this.calculated.end = start + this.cfg.visible - 1;
    if (this.calculated.end > this.cfg.listLength) {
      this.calculated.end = start + this.cfg.visible - this.cfg.listLength - 1;
    }
    const end = this.calculated.end;
    const treatedValues = this.treatValues(start, end);
  }

  treatValues(start, end) {
    const treatedValues = [];
    if (start + this.cfg.visible - 1 < this.cfg.listLength + 1) {
      for (let i = start; i < start + this.cfg.visible; i++) {
        treatedValues.push(i);
      }
    } else {
      for (let i = start; i <= this.cfg.listLength; i++) {
        treatedValues.push(i);
      }
      for (let i = 1; i <= end; i++) {
        treatedValues.push(i);
      }
    }
    this.applyDOMChanges(treatedValues);
  }

  applyDOMChanges(indexes) {
    for (let i = 0; i < this.cfg.list.length; i++) {
      const node = this.cfg.list[i];
      node.className = 'carousel3d-item';
    }
    for (let i = 0; i < indexes.length; i++) {
      const nodeIndex = indexes[i] - 1;
      const node = this.cfg.list[nodeIndex];
      node.classList.add('carousel3d-item-visible');
      node.classList.add(this.cfg.classes[i]);
    }
  }
}
