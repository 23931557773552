if ($(document).find('#twitch-count').length) {
  let httpRequest = new XMLHttpRequest();
  httpRequest.addEventListener('load', callback_);
  //httpRequest.open('GET', 'https://api.twitch.tv/kraken/channels/130610259/follows');
  //httpRequest.setRequestHeader('Client-ID', 'vyey56rldqphqdnkh40b4jqo7ftwif');
  //httpRequest.setRequestHeader('Accept', 'application/vnd.twitchtv.v5+json');
  //httpRequest.send();

  function callback_() {
    let response = JSON.parse(httpRequest.responseText);
    let total = response._total
    $('#twitch-count').text(total)
  }
}

if ($(document).find('.twitch-videos-slider-wrapper')) {
  let httpRequest = new XMLHttpRequest();
  //httpRequest.addEventListener('load', callback_);
  // Use for retrieving clips
  // httpRequest.open('GET', 'https://api.twitch.tv/kraken/clips/top?limit=3&channel=vihjeareena');

  // User for retrieving videos
  //httpRequest.open('GET', 'https://api.twitch.tv/kraken/channels/130610259/videos/?sort=views&limit=3');
  //httpRequest.setRequestHeader('Client-ID', 'vyey56rldqphqdnkh40b4jqo7ftwif');
  //httpRequest.setRequestHeader('Accept', 'application/vnd.twitchtv.v5+json');
  //httpRequest.send();

  function callback_() {
    let clipList = JSON.parse(httpRequest.responseText);
    clipList.videos.forEach(function (clip, index, array) {
      moment.locale('fi');
      $('.twitch-videos-slider-wrapper').append(
        '<div class="item">\n' +
        '    <div class="image">\n' +
        '      <img src="' + clip.preview.medium + '" alt="">\n' +
        '    </div>\n' +
        '    <p class="video-date">' + moment(clip.created_at).format('MMMM DD, Y') + '</p>\n' +
        '    <h3 class="title">' + clip.title + '</h3>\n' +
        '    <div class="link"><a href="' + clip.url + '" class="btn btn-link-arrow" target="_blank">Katso<svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
        '            <path d="M14 12l-4 4M10 8l4 4" stroke="#FD7960" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>\n' +
        '          </svg></a></div>\n' +
        '  </div>'
      )
    });

    const count = 3;
    $('.twitch-videos-slider-wrapper').addClass('slider').slick({
      slidesToShow: count < 5 ? count : 5,
      slidesToScroll: 1,
      infinite: false,
      // variableWidth: true,
      arrows: true,
      dots: false,
      prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 12l4-4M14 16l-4-4" stroke="#74B9FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
      nextArrow: '<button class="slick-next" aria-label="Next" type="button"><svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14 12l-4 4M10 8l4 4" stroke="#74B9FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg></button>',
      responsive: [
        {
          breakpoint: 1680,
          settings: {
            slidesToShow: count < 4 ? count : 4,
            slidesToScroll: 1,
            // count < 4 ? count : 4
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: count < 3 ? count : 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: count < 2 ? count : 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    })
  }
}
