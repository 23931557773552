$(document).ready(function () {
  $('.search-form').submit(function () {
    return false;
  })

  $('.search-form .search-field').attr('autocomplete', 'off')
    .on('focus', function () {
      let form = $(this).closest('.search-form');
      form.next('.search-suggestions').show();
      form.find('.search-submit').addClass('close');
    })

  $(document).on('click', '.search-form .search-submit.close', function () {
    let form = $(this).closest('.search-form');
    let suggestions = form.next('.search-suggestions');
    $(this).removeClass('close');
    suggestions.html('<span>' + suggestions.data('default-text') + '</span>').hide();
    form.find('.search-field').val('');
  })

  $(".search-field").keyup(function () {
    let elem = $(this)
    let suggestions = elem.closest('.search-form').next('.search-suggestions')
    let action = suggestions.data('action')
    let defaultText = suggestions.data('default-text')
    let val = $(this).val();
    if (val.length >= 2) {
      $.ajax({
        url: theme_ajax.url,
        data: {
          action: action,
          term: val,
        },
        type: 'POST',
        beforeSend: function () {
        },
        complete: function () {
        },
        success: function (response) {
          if (response) {
            suggestions.html(response)
          }
        },
        error: function (xhr, ajaxOptions, thrownError) {
          alert(thrownError + "\r\n" + xhr.statusText + "\r\n" + xhr.responseText);
        },
      });
    } else {
      suggestions.html('<span>' + defaultText + '</span>')
    }
  });
})
